<template >
  <div>
    <!-- ======= Hero Section ======= -->
    <Loading v-if="loading"></Loading>
    <div v-if="!loading">
      <section id="hero" class="hero d-flex align-items-center" >
      <div class="container">
        <div class="row">
          <div class="col-lg-6 d-flex flex-column justify-content-center">
            <h1 data-aos="fade-up">
              Capacity. Context. <span style="color:rgb(77 76 83)">Delivered.</span> 
            </h1>
          
           
           
            <h2 data-aos="fade-up" data-aos-delay="400">
We are the leading development consulting organisation in Ethiopia implementing economic development projects.            </h2>
            <div data-aos="fade-up" data-aos-delay="600">
              <div class="text-center text-lg-start">
                <a
                  @click="routeTo('about_us')"
                  class="
                    btn-get-started
                    scrollto
                    d-inline-flex
                    align-items-center
                    justify-content-center
                    align-self-center
                  "
                  style="border-radius: 50px;"
                >
                  <span class="about-button">
                    <span >Read About First Consult</span>
                  <i class=" bi-arrow-right "></i>
                  </span>
                  
                </a>
              </div>
            </div>
          </div>
          <div
            class="col-lg-6 hero-img"
            data-aos="zoom-out"
            data-aos-delay="200"
          >
            <img src="@/assets/img/hero-img.png" class="img-fluid" alt="" width="90%"/>
          </div>
        </div>
      </div>
    </section>
    <!-- End Hero -->
    <!-- ======= Services Section ======= -->

    <section id="values" class="values">
      <div class="container" data-aos="fade-up">
      

        <div class="row" style="padding-top:0px">
          <div data-aos="fade-up" data-aos-delay="200" class="col-lg-4 center-element aos-init aos-animate" style="margin-bottom: 20px;"><div class="row"><h3 class="sub-header">Areas of Expertise</h3><br><span style="
    font-size: 18px;
    color: #565555;
"> Our experience in Ethiopian development since 2006 spans a range of sectors and partnerships. Broadly organized, our expertise are in ... <br></span></div></div>
          <div
            v-for="(areaOf,index) in areaOfExperties" :key="index"
            class="col-lg-4"
            data-aos="fade-up"
            style="margin-bottom: 10px"
            data-aos-delay="200"
          >
            <div class="box" @click="CurrentAreaOf = areaOf" v-b-modal.modal-xl>
              <div style="width:100%" class="horizontal-center">
                <img
                :src="areaOf.icon"
                class="img-fluid"
                alt=""
                width="50%"
              />
              </div>
              
              <h3 class="text-center">{{areaOf.header}}</h3>
       <span class="colapsed-text text-center" v-html="areaOf.detail"></span>
      

            </div>
          </div>


        </div>
      </div>
      
      
        
        <b-modal
          id="modal-xl"
          size="xl"
          title="Extra Large Modal"
          ok-only
          ok-title="Close"
          hide-header>
          <div v-if="CurrentAreaOf" style="padding:50px">
        
          <div style="width:100%" class="horizontal-center">
                <img
                :src="CurrentAreaOf.icon"
                class="img-fluid"
                alt=""
                width="30%"
              />
              </div>
            <h3 class="sub-header">{{CurrentAreaOf.header}}</h3>
       <span  v-html="CurrentAreaOf.detail"></span>  </div>
          </b-modal>
        
      <br>
      <br>
    </section>
     <section id="portfolio" class="portfolio portfolio-details blog background-overlay" style="padding:0;">
      <div class="overlay">
     
      <div class="container" data-aos="fade-up" style="margin-bottom:20px;margin-top:30px">
     
          <header class="section-header" style="padding:0">
          <p class="col-md-12" style="color: white;text-align:left">Knowledge</p>
        </header>
       
        <b-row v-if="knowladge">
        <b-col md="3" v-for="(item,index) in knowladge"
              :key="index"
              style="padding:10px"
              >
       
          <b-card
            class="pdf-card"
            style="height:100%;background:transparent;
            border:solid 1px white;cursor:pointer
            "
            @click="routeTo('knowledge_materials',item)"

    :img-src="item.tumblin"
    
          >
          <div style="height: 110%; font-wight:900" class="center-element white-text">{{item.header}}</div>
          
       
          </b-card>
        </b-col>
        </b-row>
<!--         
        <div v-if="knowladge" id="list-complete-demo" class="demo">
          
          <transition-group name="list-complete" tag="p">
            <span
              v-for="(item,index) in knowladge"
              :key="index"
              class="list-complete-item col-md-3 portfolio-item"
              style="margin-bottom:30px"
              
            >
            
               <div class="portfolio-wrap" style="height:330px" @click="routeTo('knowledge_materials',item)">
                <img
                  v-bind:src="item.tumblin"
                  class="img-fluid"
                  alt=""
                  height="330px"
                  width="100%"
                />
                <div class="portfolio-info">
                  <h4>{{item.header}}</h4>
                  
                    
                
                </div>
              </div>
            </span>
          </transition-group>
        </div> -->
        
      </div>
      
  
      </div>
    
        

    </section>
    <!-- End Values Section -->



    <section id="values" class="values">
      <div class="container" data-aos="fade-up">
       
<header class="section-header">
          <p class="col-md-12 text-left">Latest</p>
        </header>
        <div class="row" style="">
          
          <div
            v-for="(news, index) in recentNews"
            :key="index"
            class="col-lg-4"
            data-aos="fade-up"
            style="margin-bottom: 20px"
            data-aos-delay="200"
          >
          
        
            <div class="box blog-box" @click="routeTo('blog_description', news)" style="padding: 0;">
              <div class="news-image" :style="{ backgroundImage: 'url(' + news.bannerImages + ')',height: '200px'}" style="background-size: cover">
          
          </div>
              <div style="padding: 30px;">
              <span class="post-date">{{ news.dateNews }}</span>
              <h3 class="post-title">{{ news.header }}</h3>
              <b-row>
               <!-- <b-button variant="primary" pill style="width: 50px;background-color:#d0d0d5 !important; border:none; color:#212529"  @click="routeTo('blog_description', news)"
                  ><i class="bi bi-arrow-right"></i
              ></b-button > -->
              </b-row>
             
              
              </div>

            </div>
          </div>


        </div>
      </div>
    </section>
 
    </div>
    


  </div>
</template>

<script>
import { getDatas, getFields, postDataHeader, postFields } from "../assets/js/service";

import Vue from "vue";
import Loading from './container/Loading.vue'

export default {

metaInfo() {
        return {
    // Children can override the title.
    title: 'Home',
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    titleTemplate: '%s ← First Consult',
    // Define meta tags here.
    meta: [
      
{property:"og:site_name" ,content:"First Consult"},
      {property:"og:title" ,content:"Home Page"},
      {property:"og:description" ,content:"First Consult is a leading economic development consulting firm implementing projects in Ethiopia. Founded in 2006, First Consult has grown to design and implement projects across the agriculture, manufacturing and service sectors. Our multidisciplinary teams combine a capacity to execute with a clarity of the local context. We have delivered at-scale real impact in terms of jobs & wealth creation, business formation & growth, and investment attraction & mobilisation."},
      {property:"og:image" ,content:"http://firstconsultet.com/img/favicons.fb3e6239.png"},
      {property:"og:url" ,content:"https//www.firstconsulet.com"},


      {name:"twitter:title" ,content:"First Consult"},
      {name:"twitter:description" ,content:"First Consult is a leading economic development consulting firm implementing projects in Ethiopia. Founded in 2006, First Consult has grown to design and implement projects across the agriculture, manufacturing and service sectors. Our multidisciplinary teams combine a capacity to execute with a clarity of the local context. We have delivered at-scale real impact in terms of jobs & wealth creation, business formation & growth, and investment attraction & mobilisation."},
      {name:"twitter:image" ,content:"http://firstconsultet.com/img/favicons.fb3e6239.png"},
      {property:"twitter:url" ,content:"https//www.firstconsulet.com"},
      {name:"twitter:card" ,content:"summary"},

      {'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8'},
      {property:"Title", content:"First Consult"},
      {name: 'description', content: 'First Consult is a leading economic development consulting firm implementing projects in Ethiopia. Founded in 2006, First Consult has grown to design and implement projects across the agriculture, manufacturing and service sectors. Our multidisciplinary teams combine a capacity to execute with a clarity of the local context. We have delivered at-scale real impact in terms of jobs & wealth creation, business formation & growth, and investment attraction & mobilisation.'}
    ],
    script: [
                { src: 'https://platform.twitter.com/widgets.js', async: true },
            ],
  }
  
},

  components:{
Loading
  },
  data() {
    return {
      fortest: false,
      recentNews: null,
      areaOfExperties:null,
      loading:false,
      CurrentAreaOf: null,
      knowladge: null,
      contactusData: {
        email: null,
        fullName: null,
        subject: null,
        comment: null,
      },
    };
  },
  mounted() {
   
    this.$store.commit('set',['loading',true]);
    let token = localStorage.getItem("token");
    getDatas("news/", token, { type: "date", value: "DESC" }, 6).then(
      (resp) => {
        this.recentNews = resp.data; 
        getFields('areaOfExperties',token).then(
      resp =>{
        this.areaOfExperties = resp.data;
        
        getDatas("Knowledge/", token, { type: "date", value: "DESC" }, 8).then(
          (resp)=>{
            this.$store.commit('set',['loading',false]);
            this.knowladge = resp.data;
          })
      }
      )
      }
    );
   
  },
  watch:{
     "$store.state.loading": {
      handler() {
        this.loading = this.$store.state.loading;
      },
    },
  },
  methods: {
   
    filterKnowledge(type) {
      this.fortest = !this.fortest;
      window.addEventListener("load", AOS.refresh);
    },
    routeTo(route, news) {
      if(route == "blog_description"){
        this.$store.commit("set", ["singlePageData", news]);
        this.$router.push(route+'/'+news.id);
      }else if(route == "knowledge_materials"){
        this.$store.commit("set",["sigleKnowledge",news])
        this.$router.push(route+"/"+news.id);
      } else {
        this.$router.push(route);
      }
    },
  },
};
</script>
<style>
.three-partition {
  width: 33.3333333%;
}
.header-card{
  box-shadow: 0px 0 5px rgb(1 41 112 / 8%);
  background-color: #012970;
  color: white;
}

.pdf-card:hover{
 background: #cccccc40 !important;
}

button {
  margin-right: 10px;
}
.demo p {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-bottom: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
}
.list-complete-item {
  transition: all 0.8s ease;

  margin-bottom: 10px;
}

.list-complete-enter-from,
.list-complete-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.list-complete-leave-active {
  position: absolute;
}

@media screen and (max-width: 720px) {
  .three-partition {
    width: 100% !important;
  }
  .news-image:hover{
    box-shadow: 0px 0 30px rgba(2, 41, 112, 0.08);
  }
}

</style>